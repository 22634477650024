html {
  background: url('../public/bwd-bg.jpg') no-repeat center center fixed; 
  background-size: cover;
  height: 100%;
  overflow: hidden;
}
/* animation */
.animate {
  animation: fadeIn 500ms ease-out backwards;
}

@keyframes fadeIn {
  from {
    transform: translateX(250px);
    opacity: 0;

  }
  to {
    transform: translateX(0px);
    opacity: 1;
    max-width: 50vw;
    height: 60vh;
  }
}
/* breakd user agent styles */
ul {
  margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
  }
/* */
.App {
  text-align: center;

}
.AppVideo {
  height: 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
  /*padding-top: calc(1280 / 720 * 100%);*/

}

.videoButtonContainer {
    display: flex;
  /*flex-direction: column;*/
  /*align-items: center;*/
  justify-content: center;
  margin-top:20px;
}
.videoButton {
  background:hsl(0, 0%, 92%);
  color:hsl(0, 0%, 23%);
  border:none;
  width:45%;
  box-shadow:1px 1px 2px rgba(21, 21, 21, 0.1);
  cursor:pointer;
  font-size:1.5rem;
  font-family: 'Dosis', sans-serif;
  margin:0 1rem;
  padding:.25rem 2rem;
  transition:all .25s ease-in-out;
}
.videoButton:focus, .videoButton:hover {
  box-shadow:1px 1px 2px rgba(21, 21, 21, 0.2);
}
.videoButton:hover {
  background:hsl(0, 0%, 88%);
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.messages h2 {
  color: rgba(255,255,255,0.8);
  text-shadow: 10px 10px 200px rgba(0, 0, 0, 0.8);
}
.socials {
  position: fixed;
  top: 6px;
  right: 26px;

}
.socials ul {
  list-style-type: none;

}
.socials > ul > li {

    cursor: pointer;
    margin-bottom: 10px;
    background: rgba(255,255,255,0.8);
    padding:  12px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    width: 24px;
    height: 24px;
    box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
}

.socials > ul > li > svg {
  fill: #000;
  width: 24px;
  pointer-events: none;

}
.comedySpecials {
  display: none;
  visibility: hidden;
}
.comedySpecials.active {
  display: block;
  visibility: inherit;
  min-width: 60vw;
  /*max-width: 80vw;*/
  max-width: 880px;
  min-height:40vh;
  max-height: 80vh;
  overflow: auto;
}
/* *******************************
  tour dates 
*/
.tourdates {
  display: none;
  visibility: hidden;
}

.tourdates.active {
  display: block;
  visibility: inherit;
  max-width: 50vw;
  height: 60vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   box-shadow: 0 2px 2px rgba(0, 0, 0, 0.9);
   border-radius: 8px;
}
.tourdates.active {
    --scrollbarBG: transparent;
    --thumbBG: #fff;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scrollbar-width: thin;
}
.tourdates.active::-webkit-scrollbar {
    width: 6px;
}
.tourdates.active::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.tourdates.active::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border: 3px solid var(--scrollbarBG);
    border-radius: 6px;
}
/************************************************

  BIT edits 1 

*/
.bit-widget {
  background: transparent!important;
  color: #fff!important;
}
.bit-event-list-title {
  font-size: 1.5rem!important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 6px;
}
.bit-logo {
  display: none;
}
.bit-logo > a > div > svg {
  fill: #fff!important;
}
.bit-top-track-button {
  display: none!important;
}
.bit-widget .bit-date {
  color: #fff!important;
    font-size: 1.5rem!important;
  margin-bottom: 6px!important;
}
.bit-mobile-date {
  color: #fff!important;
  font-size: 1.5rem!important;
  margin-bottom: 6px!important;
}
.bit-mobile-date-and-share-wrapper {
  margin-bottom: 6px!important;
}
.bit-venue, .bit-location {
  color: #000!important;
  font-size: 1.3rem!important;
  margin-bottom: 6px!important;
}
[class^=bit-location-under-] {
  font-size: 1.3rem!important;
  margin-bottom: 6px!important;
}
.bit-widget .bit-offers {
    background-color: #fff!important;
    border: 1px solid #fff!important;
    color: #000!important;

}
.bit-titleWrapper {
  margin-bottom: 6px!important;
}
.bit-widget .bit-rsvp {
    color: #fff!important;
    border: 1px solid #fff!important;
    background-color: transparent!important;
}

.bit-mobile-social-share-button {
  visibility: hidden;
  display: none!important; 
}

.bit-widget .bit-event {
  line-height: 27px!important;
}
/* play my city */
.bit-widget .bit-play-my-city-cta, .bit-follow-section-cta {
  border-color: #fff!important;
  background-color: rgba(255,255,255,0.5)!important;
  color: rgba(0,0,0,0.5)!important;

}
.bit-widget .bit-play-my-city-cta svg path, .bit-follow-section-cta svg path {
 /* fill: #000!important;*/
  fill:  rgba(0,0,0,0.5)!important;

}
.bit-widget .bit-play-my-city-heading-text {
  color: #fff!important;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.9);
}
div.bit-play-my-city-wrapper + div {
  display: none!important;
  visibility: hidden;
}
.bit-widget .bit-event:hover {
    background-color: rgba(0,0,0,0.20)!important;
}

/* Booking */
#booking, #testify {
  display: none;
  visibility: hidden;
}
#booking a, #testify a {
  color: #fff;
}
#booking.active, #testify.active {
  position: relative;
  display: block;
  visibility: inherit;
  min-width: 50vw;
  max-width: 50vw;
  height: 60vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   box-shadow: 0 2px 2px rgba(0, 0, 0, 0.9);
   border-radius: 8px;
   padding: 10px;

}
#booking.active div, #testify.active div {
  position: relative;

}
#booking ul, #testify ul {
  margin-bottom: 50px;
}
#booking ul li, #testify ul li {
  font-size: 1.4rem;
  list-style-type: none;
  text-align: left;
}
#booking ul li a, #testify ul li a {
  display: block;
  margin-bottom: 10px;
}
/* Testify Additional */
#testify img {
  max-width: 100%;
  max-height: 100%;
}
/* privacy */
#privacy {
  display: none;
  visibility: hidden;
}
#privacy p.longtext {
  text-align: left;
}
#privacy a {
  color: #fff;
}
#privacy.active {
  display: block;
  visibility: inherit;
  max-width: 50vw;
  height: 60vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   box-shadow: 0 2px 2px rgba(0, 0, 0, 0.9);
   border-radius: 8px;
   padding: 10px;
}
#privacy ul {
  margin-bottom: 50px;
}
#privacy ul li {
  font-size: 1.2rem;
  list-style-type: none;
  text-align: left;
}
/* FOOTER */
.footer {
  position: fixed;
  bottom: 0;
  font-size: 0.7rem;
  color: #000;
}
.footer div#nav-footer {
  background-color: rgba(255,255,255,0.4);
  width: 100vw;
  text-align: center;
  vertical-align: middle;
}
.footer div ul {
  vertical-align: middle;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;

}

[id^=footer-] {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(0,0,0,0.5);
    padding: 6px;
    cursor: pointer;

}
[id^=footer-]::hover {
  text-decoration: underline;
}

/* BIT edits screen sizes */

@media only screen and (max-width: 916px) {
  .bit-mobile-social-share-button {
    visibility: inherit;
    position: absolute;
    right: -60px;
    top: -11px; }
}
@media screen and (max-width: 500px) {
  .socials {
    position: fixed;
    top: 6px;
    right: inherit;
    text-align: center;
    align-items: center;
    margin-left: auto;
    magin-right: auto;
  }
  .socials > ul {
    margin:  0;
    align-items: center;
    text-align: center;

  }
  .socials > ul > li {
    float: left;
    margin-right: 10px;
    align-items: center;
    text-align: center;
  }
  .socials > ul > li > svg {
    width: 24px;
    pointer-events: none;
  }
  .tourdates.active {
    max-width: 100vw;
    background-color: rgba(0,0,0,0.2);
  }
  #booking.active, #testify.active {
    min-width: 90vw;
    max-width: 90vw;
    background-color: rgba(0,0,0,0.2);
  }

  #privacy.active {
    max-width: 90vw;
    background-color: rgba(0,0,0,0.2);
  }
  .footer div ul {
    vertical-align: middle;
    line-height: 20px;
  }
  [id^=footer-] {
    font-size: 1rem;
  }
}
@media screen and (max-width: 400px) {

.socials > ul > li {
  width: 18px;
  height: 18px;
  margin-right: 8px;

}
.socials > ul > li > svg {
    width: 18px;
    pointer-events: none;
    align-items: center;
    text-align: center;
  }


}
@media (hover: none) and (pointer: coarse) {
    /* touchscreens */
  .tourdates.active {
    background-color: rgba(0,0,0,0.2);
  }
@media (orientation: landscape) {
    #comedySpecials.active {
    min-width: 60vw;
    max-width: 60vw;
    min-height:60vh;
    max-height:60vh;

  }
}
}


